import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=71';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="71" height="75" alt="Comune di Vaiano Logo" />
  </figure>
);

export default LogoFooter;
